<template>
  <div class="page-header d-none d-lg-flex">
    <h5>{{ subtitle }}</h5>
    <select
      class="ms-3 w-auto fs-14"
      :aria-label="$t('title.common.choose_brand')"
      v-model="brandno"
      v-if="admin_login && menu_num != '9' && menu_num != '10'"
    >
      <option value="0">{{ $t("title.common.choose_brand") }}</option>
      <option :value="br.brandno" v-for="(br, index) in brandlist" :key="index">
        {{ br.brandname }}
      </option>
    </select>
    <nav style="--bs-breadcrumb-divider: ''" aria-label="breadcrumb" class="ms-auto">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/"><a>Dashboard</a></router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link
            :to="this.$route.matched[0].path && this.$route.matched[0].path"
            ><a>{{ step1 }}</a></router-link
          >
        </li>
        <li class="breadcrumb-item" v-if="step2">
          <router-link
            :to="this.$route.matched[1].path && this.$route.matched[1].path"
            ><a>{{ step2 }}</a></router-link
          >
        </li>
        <li class="breadcrumb-item" aria-current="page" v-if="step3">
          <router-link
            :to="this.$route.matched[2].path && this.$route.matched[2].path"
            ><a>{{ step3 }}</a></router-link
          >
        </li>
      </ol>
      <date-picker
        class="datepicker-main ms-auto"
        v-model="searchData.searchdate"
        valueType="format"
        type="month"
        :placeholder="$t('text.common.input_search')"
        :aria-label="$t('text.common.input_search')"
        value-format="yyyyMMdd"
      ></date-picker>
    </nav>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ko";

export default {
  name: "Location",
  components: {
    DatePicker,
  },
  // props: {
  //   usebrandno: String,
  // },
  data() {
    return {
      searchData: {
        searchdate:
          this.$route.query.searchdate ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchdate) ||
          this.$dayjs(new Date().setMonth(new Date().getMonth() - 1)).format(
            "YYYY-MM"
          ),
      },
      brandno:
        (this.$store.state.admin && this.$store.state.admin.brandno) || "0",
      brandlist:
        (this.$store.state.admin && this.$store.state.admin.brandList) || [],
      admin_login: sessionStorage.getItem("isAdmin") == "1" ? true : false,
    };
  },

  computed: {
    subtitle: function () {
      return this.$route.meta.subtitle ? this.$route.meta.subtitle : "";
    },
    step1: function () {
      return this.$route.meta.step ? this.$route.meta.step[0] : "";
    },
    step2: function () {
      return this.$route.meta.step ? this.$route.meta.step[1] : false;
    },
    step3: function () {
      return this.$route.meta.step ? this.$route.meta.step[2] : false;
    },
    name: function () {
      return this.$route.name ? this.$route.name : "";
    },
    m_subtitle: function () {
      return this.$route.meta.m_subtitle ? this.$route.meta.m_subtitle : "";
    },
    menu_num: function () {
      return this.$route.meta.menu_num || "";
    },
    sub_menu_num_last_str: function () {
      const lastStr = String(this.$route.meta.sub_menu_num || "");
      return lastStr.substr(1);
    },
    path: function () {
      console.log("matched[0]", this.$route.matched[0]);
      console.log("matched[1]", this.$route.matched[1]);
      console.log("matched[2]", this.$route.matched[2]);
    },
  },
  // methods: {
  //   checkbrand() {
  //     if(this.usebrandno) {
  //       // alert(this.usebrandno);
  //       // this.$store.commit("setBrandno", this.usebrandno);
  //       // window.location.reload(true);
  //       this.brandno = this.usebrandno;
  //     }
  //     // this.$store.commit("setBrandno", this.usebrandno);
  //   }
  // },
  // created() {
  //   this.checkbrand();
  // //   if(this.usebrandno) {
  // //     this.brandno = this.usebrandno;
  // //   }
  // },
  watch: {
    brandno(newVal) {
      this.$store.commit("setBrandno", newVal);
      window.location.reload(true);
    },
    "searchData.searchdate": function () {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
    },
  },
};
</script>
